export default function CookieConsent() {
    var cookiehint = {};

    cookiehint.check = function() {
        if ( getCookie( 'cookiesAccepted' ) === null ) {
            // Show cookie banner
            var banner = document.createElement( 'div' );
            banner.id = "cookie-alert";
            banner.innerHTML =
            '    <div class="cookie-info-wrapper rounded relative w-full bg-primarylime text-black ">\n' +
            '       <div class="cookie-outer-container w-full flex flex-wrap text-xs m-auto md:flex-no-wrap">\n' +
            '           <div class="cookie-info-content relative flex w-full flex-col flex-wrap justify-between px-8 my-4 sm:flex-row">\n' +
            '              <span class="h5 uppercase"> Diese Website verwendet Cookies!</span>' +
            '                   <div class="w-full"><div class="cookie-checkboxes flex w-full flex-col md:flex-row">\n' +
            '                   <div class="cookie-checkboxes-element mb-4 w-full md:w-3/4">\n' +
            '                         <input id="cb-tec" class="checkbox-styled" type="checkbox" disabled checked/>' +
                '                     <label for="cb-tec" class="opacity-50">' +
                '                   <span class="cb-tec-label lbl-tec">Technisch notwendige Cookies (erforderlich)</span></label>' +
                '                         </div>' +
            '                   <div class="cookie-checkboxes-element mb-4 w-full md:w-3/5">\n' +
            '                         <input id="cb-analyse" name="cb-analyse" type="checkbox">' +
            '                         <label for="cb-analyse"> ' +
        '                               <span class="tc--light-grey">Analyse-Cookies</span></label>' +
            '                          <input id="cookie-toggle-info" class="checkbox-styled hidden" type="checkbox">' +
            '                   <label for="cookie-toggle-info" class="cookie-label-detail">' +
            '                       <span class="cookie-toggle cookie-mehrInfo font-reg inline-block underline cursor-pointer">(mehr Info)</span>\n' +
            '                       <span class="cookie-toggle cookie-wenigerInfo hidden font-reg inline-block underline cursor-pointer ">weniger Info</span>\n' +
        '                       </label>' +
        '                         <div class="cookie-analyse-info cookie-label-detail h-0 overflow-hidden">' +
        '                           <ul>' +
        '                            <li><span class="font-b mr-4">_pk_ses</span><br>• Speicherdauer 30 Minuten <br> • Zweck: Dieses Cookie speichert Daten für den Besuch der Website. <br> • Anbieter: Matomo</li> ' +
        '                            <li><span class="font-b mr-4">_pk_id</span><br>• Speicherdauer 13 Monate <br> • Zweck: Dieses Cookie speichert Details über den User wie zum Beispiel die Unique Visitor ID. <br> • Anbieter: Matomo </li> ' +
        '                           </ul>' +
        '                           <p>Diese Cookies sammeln aggregierte Informationen darüber, wie unsere Webseite genutzt wird, um folglich deren Attraktivität, Inhalt und Funktionalität zu verbessern. Sie werden nur mit Ihrer Einwilligung eingesetzt und von Matomo dafür verwendet, um Seitenaufrufe zu zählen und die durchschnittliche Verweildauer zu errechnen. </p>' +
        '                         </div></div>' + '                <div class="cookie-info-buttons w-full md:-mt-2 md:text-right">\n' +
            '                   <button id="cookie-alert-decline" class="inline-block rounded-lg font-b px-4 py-2 mb-4 mr-2 md:mb-2 shadow bg-primarymedium hover:opacity-75"><span>Auswahl speichern</span></button>\n' +
            '                   <button id="cookie-alert-accept" class="inline-block rounded-lg font-b px-4 py-2 mb-4 shadow bg-white hover:opacity-75"><span>Alle Cookies erlauben</span></button>\n' +
            '               </div>\n' +
    '       </div>' +
            '                   ' +
            '               <div class="cookie-info-text inline-block w-full md:w-3/5 mt-2">\n' +
            '                    <p>Klicken Sie „Alle Cookies erlauben“, um alle Arten von Cookies zu akzeptieren, oder treffen Sie Ihre individuelle Einstellung.' +
            '                       Details zu unseren Cookies finden Sie in unserer <a href="https://www.agr.at/datenschutz">Datenschutzrichtlinie</a>.</p>\n' +
            '               </div>\n' +
            '             </div></div>\n' +
            '        </div>\n' +
            '    </div>\n';

            banner.addEventListener( 'click', function( e ) {
                if ( e.target ) {
                    var targetButton = e.target;
                    if ( e.target.tagName == 'SPAN' ) {
                        targetButton = e.target.parentNode;
                    }
                    if ( targetButton.id == 'cookie-alert-accept' ) {
                        cookiehint.acceptCookies( true );
                    }
					if ( targetButton.id == 'cookie-alert-decline' ) {
						if(document.getElementById("cb-analyse").checked) {
							cookiehint.acceptCookies( true );
						}
						else {
							cookiehint.acceptCookies( false );
						}
					}
                }
            } );
            document.body.appendChild( banner );
        } else if ( getCookie( 'cookiesAccepted' ) == 1 ) {
            onAccepted();
        }
    }

    cookiehint.acceptCookies = function( accepted ) {
        // Set cookie
        var date = new Date();
        date.setTime( date.getTime() + (365 * 24 * 60 * 60 * 1000) ); // 5 years
        document.cookie = "cookiesAccepted=" + (accepted == 1 ? 1 : 0) + "; expires=" + date.toUTCString() + "; path=/";

        if ( accepted == 1 ) {
            onAccepted();
        }

        // remove cookie banner
        var banner = document.getElementById( "cookie-alert" );
        banner.parentNode.removeChild( banner );
    }

    function getCookie( name ) {
        var nameEQ = name + "=";
        var ca = document.cookie.split( ';' );
        for ( var i = 0; i < ca.length; i++ ) {
            var c = ca[ i ];
            while ( c.charAt( 0 ) == ' ' ) c = c.substring( 1, c.length );
            if ( c.indexOf( nameEQ ) == 0 ) return c.substring( nameEQ.length, c.length );
        }
        return null;
    }

    return cookiehint;
}
function onAccepted(){
    /*(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-P8443LT');*/


    //Matomo
    var _paq = window._paq = window._paq || [];
    /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
    _paq.push(['trackPageView']);
    _paq.push(['enableLinkTracking']);
    (function() {
        var u="//www.matoma.agr.at/";
        _paq.push(['setTrackerUrl', u+'matomo.php']);
        _paq.push(['setSiteId', '2']);
        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.type='text/javascript'; g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
    })();
}
